import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NumberListComponent from "./components/NumberListComponent";
import LoginPage from "./components/LoginPage";
import PrivateRoute from "./routes/PrivateRoute";
import axiosInstance from "./utils/axiosInstance";
import LandingPage from "./components/LandingPage";
import AuthLayout from "./layouts/AuthLayout";
import LandingLayout from "./layouts/LandingLayout";
import MainLayout from "./layouts/MainLayout";
import LoadingArea from "./components/LoadingArea";
import { Box, Container } from "@mui/material";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Landing page layout */}
        <Route
          path="/"
          element={
            <LandingLayout>
              <LandingPage />
            </LandingLayout>
          }
        />

        {/* Main app layout for authenticated pages */}
        <Route
          path="/number-list"
          element={
            <PrivateRoute>
              <MainLayout>
                <NumberListComponent />
              </MainLayout>
            </PrivateRoute>
          }
        />

        {/* Authentication pages layout */}
        <Route
          path="/login"
          element={
            <AuthLayout>
              <LoginPage />
            </AuthLayout>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
