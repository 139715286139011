import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TextField,
  Select,
  MenuItem,
  TablePagination,
  Container,
  Box,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import useNumberList from "../hooks/useNumberList";
import { FormatListNumbered, Search } from "@mui/icons-material";
import RequestNumberComponent from "./RequestNumberComponent";

const NumberListComponent: React.FC = () => {
  const {
    numbers,
    searchTerm,
    setSearchTerm,
    statusFilter,
    setStatusFilter,
    sortOrder,
    setSortOrder,
    timeRemaining,
    page,
    rowsPerPage,
    totalRecords,
    handleChangePage,
    handleChangeRowsPerPage,
    getCountryName,
    getProjectName,
    formatDate,
    syncNumberListData,
  } = useNumberList();

  return (
    <Container sx={{ my: "2rem" }}>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <TextField
          label="Search Number"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: "1rem" }}
        />
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          displayEmpty
          variant="outlined"
          style={{ marginRight: "1rem", minWidth: 150 }}
        >
          <MenuItem value="">
            <em>All Statuses</em>
          </MenuItem>
          <MenuItem value="Waiting">Waiting</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="Timeout">Timeout</MenuItem>
        </Select>
        <Select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          displayEmpty
          variant="outlined"
          style={{ minWidth: 150 }}
        >
          <MenuItem value="desc">
            <em>Newest First</em>
          </MenuItem>
          <MenuItem value="asc">Oldest First</MenuItem>
        </Select>
      </Box> */}
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <RequestNumberComponent onNumberRequested={syncNumberListData} />

        <Box
          borderRadius="16px"
          padding="0.2rem"
          boxShadow="1px 1px 1px 1px rgba(0, 0, 0, 0.1)"
          bgcolor="#ffffff"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1rem"
            borderBottom={0.3}
            borderColor={"#555555"}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                marginRight="1rem"
                gap={1}
              >
                <FormatListNumbered
                  sx={{
                    color: "#616161",
                    fontFamily: "Inter",
                    fontSize: "24px",
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    margin: 0,
                    color: "#616161",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "36px",
                    letterSpacing: "-0.504px",
                  }}
                >
                  Number List
                </Typography>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" alignItems="center">
                <span style={{ marginRight: "0.5rem" }}>Filter by:</span>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  displayEmpty
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 150, borderRadius: "8px" }}
                >
                  <MenuItem value="">
                    <em>All Status</em>
                  </MenuItem>
                  <MenuItem value="Waiting">Waiting</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Timeout">Timeout</MenuItem>
                </Select>
              </Box>

              <Divider
                orientation="vertical"
                flexItem
                // style={{ marginLeft: "1rem", marginRight: "1rem" }}
              />

              <Box display="flex" alignItems="center">
                <span style={{ marginRight: "0.5rem" }}>Sort by:</span>
                <Select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                  displayEmpty
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 150, borderRadius: "8px" }}
                >
                  <MenuItem value="desc">
                    <em>Newest First</em>
                  </MenuItem>
                  <MenuItem value="asc">Oldest First</MenuItem>
                </Select>
              </Box>

              <Divider
                orientation="vertical"
                flexItem
                // style={{ marginLeft: "1rem", marginRight: "1rem" }}
              />

              <TextField
                placeholder="Search number..."
                variant="outlined"
                value={searchTerm}
                size="small"
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  classes: {
                    notchedOutline: "customOutline",
                  },
                }}
                sx={{
                  minWidth: 200,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Box>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow sx={{ fontWeight: 600 }}>
                  <TableCell>No</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>SMS</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell>Created Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {numbers.map((number, index) => (
                  <TableRow
                    key={index}
                    style={{
                      cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating row colors
                    }}
                  >
                    <TableCell align="left">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell>{getCountryName(number.country)}</TableCell>
                    <TableCell>{getProjectName(number.service)}</TableCell>
                    <TableCell>{number.number}</TableCell>
                    <TableCell>
                      {number.sms || "Not received sms yet"}
                    </TableCell>
                    <TableCell align="center">
                      <StatusChip
                        status={number.status}
                        timeRemaining={timeRemaining[number.request_id]}
                      />
                    </TableCell>
                    <TableCell>{formatDate(number.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `Showing ${from} to ${to} of ${count} entries`
              }
            />
          </TableContainer>
        </Box>
      </Box>
    </Container>
  );
};

interface StatusChipProps {
  status: string;
  timeRemaining?: string;
}

const StatusChip: React.FC<StatusChipProps> = ({ status, timeRemaining }) => {
  switch (status) {
    case "Waiting":
      return (
        <Chip
          size="small"
          label={`Waiting ${timeRemaining ? `(${timeRemaining})` : ""}`}
          sx={{ backgroundColor: "#FF9800", color: "white", px: 2 }} // Orange color
        />
      );
    case "Completed":
      return (
        <Chip
          size="small"
          label="Completed"
          sx={{ backgroundColor: "#2196F3", color: "white", px: 2 }} // Blue color
        />
      );
    case "Timeout":
      return (
        <Chip
          size="small"
          label="Timeout"
          sx={{ backgroundColor: "#F44336", color: "white", px: 2 }} // Red color
        />
      );
    default:
      return null;
  }
};

export default NumberListComponent;
