import {
  Country,
  Project,
  NumberResponse,
  SmsResponse,
  NumberData,
} from "../types";
import { GET, POST } from "../utils/axiosInstance";

const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3002/api";

export const getNumbers = async (
  limit: number,
  offset: number,
  searchTerm: string = "",
  statusFilter: string = "",
  sortOrder: string = "desc"
): Promise<{ numbers: NumberData[]; total: number }> => {
  const response = await GET(`${API_BASE_URL}/numbers`, {
    params: {
      limit,
      offset,
      searchTerm,
      statusFilter,
      sortOrder,
    },
  });

  return response.data;
};

export const getCountries = async (): Promise<Country[]> => {
  const response = await GET<Country[]>(`${API_BASE_URL}/countries`);
  return response.data;
};

export const getProjects = async (): Promise<Project[]> => {
  const response = await GET<Project[]>(`${API_BASE_URL}/projects`);
  return response.data;
};

export const requestNewNumber = async (
  country: Country,
  project: Project
): Promise<NumberResponse> => {
  const response = await POST<NumberResponse>(
    `${API_BASE_URL}/request-number`,
    {
      country,  // Sending the ID of the selected country
      project,  // Sending the ID of the selected project
    }
  );
  return response.data;
};

export const getSms = async (requestId: string): Promise<SmsResponse> => {
  const response = await GET<SmsResponse>(
    `${API_BASE_URL}/sms?requestId=${requestId}`
  );
  return response.data;
};
