import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
  CircularProgress,
  Grid,
  Link,
} from "@mui/material";
import axios from "axios";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const API_BASE_URL =
        process.env.REACT_APP_BACKEND_URL || "http://localhost:3002/api";

      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        username,
        password,
      });
      localStorage.setItem("authToken", response.data.token);
      navigate("/number-list");
    } catch (err) {
      setError("Invalid credentials");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              borderRadius: "16px",
              display: "flex",
              overflow: "hidden",
              boxShadow: 3,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: { xs: "none", md: "block" },
                    height: "100%",
                  }}
                >
                  <img
                    src="/login-bg.jpeg"
                    alt="Login Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 4,
                }}
              >
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontFamily: "Inter",
                    marginBottom: "24px",
                    color: "text.primary",
                    fontWeight: "bold",
                  }}
                >
                  Login to AiConec E-SIM
                </Typography>
                <Box
                  component="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Username"
                    autoFocus
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    sx={{ marginBottom: 2 }}
                  />
                  {error && (
                    <Typography color="error" sx={{ marginBottom: 2 }}>
                      {error}
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    disabled={loading}
                    sx={{
                      marginTop: 2,
                      padding: 1.5,
                      backgroundColor: "#007BFF",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "LOGIN"
                    )}
                  </Button>
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ marginTop: 3 }}
                  >
                    By signing up, you agree to our&nbsp;
                    <Link
                      href="#"
                      sx={{ color: "#007BFF", textDecoration: "none" }}
                    >
                      Terms & Service
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      href="#"
                      sx={{ color: "#007BFF", textDecoration: "none" }}
                    >
                      Privacy Policy
                    </Link>
                  </Typography> */}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
