import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import { Navigate } from "react-router-dom";

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("authToken");

  if (isAuthenticated) {
    return <Navigate to="/number-list" />;
  }

  return (
    <Container component="main" sx={{ flexGrow: 1, minHeight: "100vh" }}>
      {children}
    </Container>
  );
};

export default AuthLayout;
