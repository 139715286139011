import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SignInAltIcon from "@mui/icons-material/ExitToApp";

const LandingPage: React.FC = () => {
  return (
    <Box
      sx={{
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
        paddingX: 6,
        background:
          "radial-gradient(177.74% 111.8% at 100% 0%, #CFDEFF 0%, #4968AA 100%)",
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "var(--Neutral-10, #FFF)",
            // fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: 1,
          }}
        >
          Welcome to AiConec e-SIM
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: "var(--Neutral-10, #FFF)",
            // fontFamily: "Montserrat",
            fontSize: "48px",
            fontWeight: 600,
            lineHeight: "normal",
            marginBottom: 3,
          }}
        >
          Instant Activation
          <br />
          Unlimited Connection
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "var(--Neutral-10, #FFF)",
            // fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "36px",
            marginBottom: 4,
          }}
        >
          Get the freedom to communicate without the need for a physical card.
          Our e-SIM allows you to activate a new number in seconds.
        </Typography>
        <Button
          variant="contained"
          sx={{
            padding: "12px 32px",
            fontSize: "18px",
            backgroundColor: "#374763", // Darker background for the button
            color: "white",
            borderRadius: "30px",
            textTransform: "none",
            boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#4A5874",
            },
          }}
          startIcon={<SignInAltIcon />}
          href="/login"
        >
          Log in here
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <img
          src="/image-landing.png"
          alt="AiConec e-SIM"
          style={{
            maxWidth: "100%",
            height: "auto",
            transform: "rotate(-2deg)",
          }} // Ensure correct scaling and slight rotation for the image
        />
      </Box>
      <Link
        href="https://wa.me/62895402495052"
        target="_blank"
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#25D366",
          color: "white",
          padding: "10px 20px",
          borderRadius: "50px",
          textDecoration: "none",
          fontSize: "16px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <WhatsAppIcon />
        WhatsApp Us
      </Link>
    </Box>
  );
};

export default LandingPage;
