import React from "react";
import { Box, Typography } from "@mui/material";

const FooterBar: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        py: 2,
        px: 3,
        mt: "auto",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        borderTop: "1px solid #E0E0E0",
      }}
    >
      <Typography variant="body2" sx={{ color: "#616161" }}>
        © 2024, made by AiConec
      </Typography>
    </Box>
  );
};

export default FooterBar;
