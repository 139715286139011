import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import { Navigate } from "react-router-dom";

interface LandingLayoutProps {
  children: ReactNode;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("authToken");

  if (isAuthenticated) {
    return <Navigate to="/number-list" />;
  }

  return (
    <Box
      component="main"
      sx={{ flex: 1, minHeight: "100vh", minWidth: "100vw" }}
    >
      {children}
    </Box>
  );
};

export default LandingLayout;
