import React from "react";
import { AppBar, Toolbar, Typography, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";

const NavigationBar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const isAuthenticated = !!localStorage.getItem("authToken");

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Toolbar>
        {/* Left side with logo */}
        <Box display="flex" alignItems="center" gap={1}>
          <img src="/AiConec - eSIM 1.png" alt="eSIM Logo" height="40px" />
        </Box>
        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />
        {/* Right side with user profile */}
        {isAuthenticated && (
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton
              onClick={handleLogout}
              sx={{
                gap: 1,
                color: "red",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Typography sx={{ marginLeft: 1 }}>Logout</Typography>
              <Logout />
            </IconButton>
            {/* <UserProfile /> */}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
