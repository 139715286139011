import { useState, useEffect } from "react";
import {
  getNumbers,
  getSms,
  getCountries,
  getProjects,
} from "../services/numbersApi";
import { NumberData, Country, Project, SmsResponse } from "../types";

const useNumberList = () => {
  const [numbers, setNumbers] = useState<NumberData[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [timeRemaining, setTimeRemaining] = useState<{ [key: string]: string }>(
    {}
  );
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const syncNumberListData = async () => {
    try {
      const [numbersData, countriesData, projectsData] = await Promise.all([
        getNumbers(
          rowsPerPage,
          page * rowsPerPage,
          searchTerm,
          statusFilter,
          sortOrder
        ),
        getCountries(),
        getProjects(),
      ]);

      setNumbers(numbersData.numbers);
      setTotalRecords(numbersData.total);
      setCountries(countriesData);
      setProjects(projectsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    syncNumberListData();
  }, [page, rowsPerPage, searchTerm, statusFilter, sortOrder]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const waitingNumbers = numbers.filter(
        (number) => number.status === "Waiting"
      );
      waitingNumbers.forEach((number) => {
        fetchSmsUntilReceived(number.request_id);
      });
    }, 10000); // Retry every 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [numbers]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime();
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [numbers]);

  const fetchSmsUntilReceived = async (requestId: string) => {
    const smsResponse: SmsResponse = await getSms(requestId);

    setNumbers((prevNumbers) =>
      prevNumbers.map((number) =>
        number.request_id === requestId
          ? {
            ...number,
            sms: smsResponse.sms,
            status: smsResponse.status,
          }
          : number
      )
    );
  };

  const updateRemainingTime = () => {
    const newTimeRemaining = numbers.reduce((acc, number) => {
      if (number.status === "Waiting") {
        const timeRemaining = getTimeRemaining(number.createdAt);
        acc[number.request_id] = timeRemaining;
      }
      return acc;
    }, {} as { [key: string]: string });
    setTimeRemaining(newTimeRemaining);
  };

  const getTimeRemaining = (createdAt: string) => {
    const timeoutDuration = 15 * 60 * 1000; // 15 minutes
    const createdTime = new Date(createdAt).getTime();
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - createdTime;
    const timeRemaining = timeoutDuration - timeElapsed;

    if (timeRemaining <= 0) {
      return "00:00";
    }

    const minutes = Math.floor(timeRemaining / 60000);
    const seconds = Math.floor((timeRemaining % 60000) / 1000);

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCountryName = (countryCode: string) => {
    const country = countries.find((c) => c.id === Number(countryCode));
    return country ? country.title : countryCode;
  };

  const getProjectName = (projectCode: string) => {
    const project = projects.find((p) => p.id === Number(projectCode));
    return project ? project.title : projectCode;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Converts to local timezone
  };

  return {
    numbers,
    countries,
    projects,
    searchTerm,
    setSearchTerm,
    statusFilter,
    setStatusFilter,
    sortOrder,
    setSortOrder,
    timeRemaining,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecords,
    handleChangePage,
    handleChangeRowsPerPage,
    getCountryName,
    getProjectName,
    formatDate,
    syncNumberListData,
  };
};

export default useNumberList;
