import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";
import NavigationBar from "../components/NavigationBar";
import FooterBar from "../components/FooterBar";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <NavigationBar />
      <Container component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Container>
      <FooterBar />
    </Box>
  );
};

export default MainLayout;
